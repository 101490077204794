import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    awlrPopup:{
        clicked:false,awlr_name:'Napel',das:'Napel Ngawi',
        sungai:'Bengawan Solo',koordinat:[0,0],
        tma:0,last_update:0,
    },
    simulasiPopup:{},
    simulasi_radio:'tma',
    simulasi_slider:{max:100,min:0,steps:10},
    simulasi_slider_value:0,
    simulasi_slider_luas:0,
    simulasi_slider_jumlah_bangunan:0,
    recalculate_bangunan:false,
    simulasi_btn:{genangan:false,kedalaman:false,kecepatan:false},
    bangunan_checkbox:false,
    adm_checkbox:false,das_checkbox:false
  },
  mutations: {
    awlr_clicked(state,payload) {
        state.awlrPopup=payload
    },
    simulasi_radio_changed(state,val){
      state.simulasi_radio=val
    },
    simulasi_slider_meta(state,payload){
      state.simulasi_slider=payload
    },
    simulasi_slider_changed(state,val){
      state.simulasi_slider_value=val
    },
    simulasi_slider_luas_changed(state,val){
      state.simulasi_slider_luas=val
    },
    simulasi_slider_jumlah_bangunan_changed(state,val){
      state.simulasi_slider_jumlah_bangunan=val
    },
    simulasi_btn_changed(state,payload){
      state.simulasi_btn=payload
    },
    bangunan_checkbox_checked(state,val){
      state.bangunan_checkbox=val
    },
    recalculate_bangunan_changed(state){
      if(state.recalculate_bangunan==true)state.recalculate_bangunan=false
      else state.recalculate_bangunan=true
    },
    adm_checkbox_checked(state,val){
      state.adm_checkbox=val
    },
    das_checkbox_checked(state,val){
      state.das_checkbox=val
    }
  }
})

export default store