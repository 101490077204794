<template>
    <v-card id='side-bar' tile>
        <v-card-text>
            <v-row>
                <v-col cols=12>
                    <v-container class='side-bar-title'>
                        <h4>{{header['1']}}</h4>
                    </v-container>
                    <v-row >
                        <v-col cols=4 align=center>
                            <v-btn large plain block @click="changeBasemap('osm')">
                                <v-img src='@/assets/img/basemap_osm.png' width=40></v-img>
                            </v-btn>
                            <p>OSM</p>
                        </v-col>
                        <v-col cols=4 align=center>
                            <v-btn large plain block @click="changeBasemap('topo')">
                                <v-img src='@/assets/img/basemap_topo.png' width=40></v-img> 
                            </v-btn>
                            <p>Topografi</p>
                        </v-col>
                        <v-col cols=4 align=center>
                            <v-btn large plain block @click="changeBasemap('sattelite')">
                                <v-img src='@/assets/img/basemap_sattelite.png' width=40></v-img>
                            </v-btn>
                            <p>Satelit</p>
                        </v-col>
                        <v-col cols=6>
                            <v-checkbox
                            label="DAS"
                            color="red"
                            v-model="das_check"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols=6>
                            <v-checkbox
                            label="Administrasi"
                            color="red"
                            v-model="adm_check"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols=12>
                    <v-container class='side-bar-title'>
                        <h4>{{header['2']}}</h4>
                    </v-container>
                    <div v-if="!awlrInfo.clicked">
                        <v-row>
                            <v-col cols=2>
                                <v-img src='@/assets/img/triangle.png' width=25></v-img>
                            </v-col>
                            <v-col cols=10>
                                AWLR STATUS SIAGA IV
                            </v-col>
                            <v-col cols=2>
                                <v-img src='@/assets/img/triangle-yellow.png' width=25></v-img>
                            </v-col>
                            <v-col cols=10>
                                AWLR STATUS SIAGA III
                            </v-col>
                            <v-col cols=2>
                                <v-img src='@/assets/img/triangle-orange.png' width=25></v-img>
                            </v-col>
                            <v-col cols=10>
                                AWLR STATUS SIAGA II
                            </v-col>
                            <v-col cols=2>
                                <v-img src='@/assets/img/triangle-red.png' width=25></v-img>
                            </v-col>
                            <v-col cols=10>
                                AWLR STATUS SIAGA I
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else>
                    <v-row>
                        <v-col cols=12>
                            <line-chart></line-chart>
                        </v-col>
                        <v-col cols=12>
                            <v-simple-table dense class='siderbar-table'>
                                <tbody>
                                    <tr>
                                        <td class='sidebar-text'>AWLR</td>
                                        <td class='sidebar-text'><b>{{awlrInfo.awlr_name}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>DAS</td>
                                        <td class='sidebar-text'><b>{{awlrInfo.das}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>Wilayah Sungai</td>
                                        <td class='sidebar-text'><b>{{awlrInfo.sungai}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>Koordinat</td>
                                        <td class='sidebar-text'><b>{{awlrInfo.koordinat[0]}}, {{awlrInfo.koordinat[1]}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>TMA</td>
                                        <td class='sidebar-text'>
                                            <v-row>
                                                <v-col cols=4>
                                                    <b>+ {{awlrInfo.tma}}.0</b>
                                                </v-col>
                                                <v-col cols=6>
                                                    <v-btn color='success' x-small block>SIAGA IV</v-btn>
                                                </v-col>
                                                <v-col cols=2>
                                                    <v-btn icon x-small>
                                                        <v-icon>mdi-refresh</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    </div>
                </v-col>
                <v-col cols=12>
                    <div v-if="awlrInfo.clicked" >
                        <v-container class='side-bar-title'>
                            <h4>{{header['3']}}</h4>
                        </v-container>
                        <v-row>
                            <v-col cols=4>
                                <v-btn small @click="activateSimulasiBtn('genangan')">
                                    <v-img src='@/assets/img/icon_genangan.png' width=45></v-img>
                                </v-btn>
                                <p>Genangan</p>
                            </v-col>
                            <v-col cols=4>
                                <v-btn small @click="activateSimulasiBtn('kedalaman')">
                                    <v-img src='@/assets/img/icon_kedalaman.png' width=45></v-img>
                                </v-btn>
                                <p>Kedalaman</p>
                            </v-col>
                            <v-col cols=4>
                                <v-btn small @click="activateSimulasiBtn('kecepatan')">
                                    <v-img src='@/assets/img/icon_kecepatan.png' width=45></v-img>
                                </v-btn>
                                <p>Kecepatan</p>
                            </v-col>
                            <v-col cols=6>
                                <v-radio-group dense @change='changeSimulasiRadio()' v-model="simulasi_radio_val">
                                <v-radio
                                    label="TMA"
                                    value="tma"
                                ></v-radio>
                                <v-radio
                                    label="Kala Ulang"
                                    value="ulang"
                                ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols=6>
                                <v-slider
                                v-model='simulasi_slider_val'
                                min=0
                                max=18000
                                :step=step_slider
                                @end='changeSimulasiSlider()'
                                ></v-slider>
                                <div>{{simulasi_slider_val}}</div>
                            </v-col>
                            <v-col cols=12 v-if='rumah_check==true'>
                                <v-btn :loading='isLoading' @click='recalculateBangunan()' color='primary' x-small block>Kalkulasi Ulang Bangunan Terdampak</v-btn>
                            </v-col>
                            <v-col cols=12>
                                <v-simple-table dense class='siderbar-table'>
                                <tbody>
                                    <tr>
                                        <td class='sidebar-text'>Kala Ulang</td>
                                        <td class='sidebar-text'>
                                            <v-row>
                                                <v-col cols=6>
                                                    <b>Q2</b>
                                                </v-col>
                                                <v-col cols=3>
                                                    TMA
                                                </v-col>
                                                <v-col cols=3>
                                                    + {{awlrInfo.tma}}.0
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>Debit</td>
                                        <td class='sidebar-text'><b>{{$store.state.simulasi_slider_value}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>Luas Genangan</td>
                                        <td class='sidebar-text'><b>{{$store.state.simulasi_slider_luas}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>Bangunan Terdampak</td>
                                        <td class='sidebar-text'><b>{{$store.state.simulasi_slider_jumlah_bangunan}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class='sidebar-text'>Kerugian</td>
                                        <td class='sidebar-text'>
                                            <b>Rp. {{$store.state.simulasi_slider_jumlah_bangunan*180000000}}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            </v-col>
                            <v-col cols=12>
                                <v-checkbox
                                v-model='rumah_check'
                                label="Bangunan Terdampak"
                                color="red"
                                value=""
                                @change='rumahCheckBox()'
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import LineChart from './sidebar/lineChart.vue'
export default {
    components:{
        "line-chart":LineChart
    },
    data(){
        return{
            bm:'osm',header:{1:'KONTROL LAYERS',2:'LEGENDA',3:'SIMULASI BANJIR'},
            awlrInfo:{
                    clicked:false,awlr_name:'Napel',das:'Napel Ngawi',
                    sungai:'Bengawan Solo',koordinat:[0,0],
                    tma:0,last_update:0,
            },
            // siagaBtn:[
            //     {'title':'SIAGA I','color':''}
            // ],
            simulasi_radio_val:'tma',
            step_slider:100,
            simulasi_slider_val:0,
            simulasi_btn_val:{genangan:false,kedalaman:false,kecepatan:false},
            rumah_check:false,adm_check:false,das_check:false,isLoading:false
        }
    },
    watch:{
        '$store.state.awlrPopup':{
            handler(val){
                this.awlrInfo=val
                this.header['2']='INFO AWLR'
                console.log(this.header['2'])
            },
            deep: true
        },
        simulasi_radio_val:{
            handler(val){
                if(val=='tma') this.step_slider=100
                else this.step_slider=4500
            }
        },
        adm_check:{
            handler(val){
                this.$store.commit('adm_checkbox_checked',val)
            }
        },
        das_check:{
            handler(val){
                this.$store.commit('das_checkbox_checked',val)
            }
        }
    },
    methods:{
        changeBasemap(val){
            this.$emit('basemap',val)
        },
        changeSimulasiRadio(){
            this.$store.commit('simulasi_radio_changed',this.simulasi_radio_val)
        },
        changeSimulasiSlider(){
            this.$store.commit('simulasi_slider_changed',this.simulasi_slider_val)
        },
        activateSimulasiBtn(btn){
            if(this.simulasi_btn_val[btn]){
                this.simulasi_btn_val[btn]=false
            }else{
                this.simulasi_btn_val[btn]=true
            }
                this.$store.commit('simulasi_btn_changed',this.simulasi_btn_val)
        },
        rumahCheckBox(){
            this.$store.commit('bangunan_checkbox_checked',this.rumah_check)
        },
        async refreshBangunan(){
            this.isLoading=true
            this.$store.commit("recalculate_bangunan_changed")
        },
        async recalculateBangunan(){
            await this.refreshBangunan()
            this.isLoading=false
        }
    }   
}
</script>