<template>
    <v-app>
      <main-dialog :activate="welcomeDialog"></main-dialog>
      <v-app-bar id="main-nav" color="#1B3C5D">  
        <v-btn text href='/'>
          <div id="main-logo">
            <!-- <h2>Rivermonitoring.ID</h2> -->
            <v-img src='./assets/img/logo_header.png' contain width=150></v-img>
          </div>
        </v-btn>
        <!-- <v-btn v-else text @click="welcomeDialog=false">
          <div id="main-logo">
            <h2>Rivermonitoring.ID</h2>
            <v-img src='./assets/img/logo_header.png'></v-img>
          </div>
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn icon dark v-if='welcomeDialog==true' @click="welcomeDialog=false">
          <v-icon>mdi-message-alert</v-icon>
        </v-btn>
        <v-btn icon dark v-else @click="welcomeDialog=true">
          <v-icon>mdi-message-alert</v-icon>
        </v-btn>
        <!-- <div id="nav">
          <router-link to="/">Beranda</router-link> |
          <router-link to="/about">Tentang</router-link> |
          <router-link to="/about">Panduan</router-link> |
          <router-link to="/about">Kontak</router-link>
        </div> -->
      </v-app-bar>
        <v-main>
         <!-- <router-view/> -->
         <home-map></home-map>
        </v-main>
    </v-app>
</template>
<script>
import mainDialog from "./components/WelcomeModal.vue";
import homeMap from "./views/Home.vue"
export default {
  components:{mainDialog,homeMap},
  data(){
    return{
      welcomeDialog:true
    }
  }
}
</script>