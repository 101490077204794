<template>
    <v-dialog v-model='dialog' id='main-dialog' max-width="600px">
        <v-card>
            <v-card-title class="text-h5">
                <v-img style="margin-bottom:15px;" src='../assets/img/logo_fullcolor.png'></v-img>
            <!-- Flood Inundation Mapper -->
                <v-toolbar
                color="#1B3C5D"
                height=20
                dark
                flat
                >
                <template v-slot:extension>
                    <v-tabs
                    v-model="tab"
                    align-with-title
                    >
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab
                        v-for="(item,idx) in items"
                        :key="idx"
                    >
                        {{ item.title }}
                    </v-tab>
                    </v-tabs>
                </template>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(item,idx) in items"
                    :key="idx"
                >
                    <v-card flat>
                    <v-card-text>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente, voluptatibus. Nobis quas deserunt facilis velit, dolorem illo magnam natus earum iure odit illum nisi animi minima eos aliquid voluptatem in!</v-card-text>
                    </v-card>
                </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="#1B3C5D"
                text
                @click="dialog = false"
            >
                Close
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
  export default {
    props:['activate'],
    data () {
      return {
        dialog: false,
        tab: null,
        items: [
            {title:'About the Application',icon:'map-marker-question-outline'},
            {title:'Welcome & Disclaimer',icon:'information-outline'},
        ],
      }
    },
    watch:{
        activate:{
            handler(){
                this.dialog=true
            }
        }
    },
    mounted(){
        this.dialog=true
    }
  }
</script>
