<template>
   <div id="chart">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
export default {
  name: 'LineChart',
  components:{
    apexchart: VueApexCharts,
  },
  data() {
    return {
      sampleData:[
        {
            "tanggal": "14 Jul 2022 00:00:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 00:05:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 00:10:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 00:15:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 00:25:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 00:35:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 00:40:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 00:45:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 00:55:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 01:00:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 01:05:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 01:10:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 01:15:00",
            "tma": 51.25,
            "debit": 167.349
        },
        {
            "tanggal": "14 Jul 2022 01:20:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 01:25:00",
            "tma": 51.21,
            "debit": 161.209
        },
        {
            "tanggal": "14 Jul 2022 01:30:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 01:40:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 01:45:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 02:00:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 02:10:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 02:15:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 02:25:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 02:30:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 02:35:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 02:45:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 02:50:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 03:00:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 03:05:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 03:10:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 03:15:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 03:25:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 03:30:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 03:35:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 03:40:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 03:45:00",
            "tma": 51.19,
            "debit": 158.177
        },
        {
            "tanggal": "14 Jul 2022 03:50:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 03:55:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 04:10:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 04:15:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 04:20:00",
            "tma": 51.2,
            "debit": 159.69
        },
        {
            "tanggal": "14 Jul 2022 04:30:00",
            "tma": 51.21,
            "debit": 161.209
        },
        {
            "tanggal": "14 Jul 2022 04:35:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 04:40:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 04:45:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 04:50:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 04:55:00",
            "tma": 51.24,
            "debit": 165.804
        },
        {
            "tanggal": "14 Jul 2022 05:00:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 05:10:00",
            "tma": 51.23,
            "debit": 164.266
        },
        {
            "tanggal": "14 Jul 2022 05:15:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 05:20:00",
            "tma": 51.21,
            "debit": 161.209
        },
        {
            "tanggal": "14 Jul 2022 05:25:00",
            "tma": 51.21,
            "debit": 161.209
        },
        {
            "tanggal": "14 Jul 2022 05:30:00",
            "tma": 51.22,
            "debit": 162.734
        },
        {
            "tanggal": "14 Jul 2022 05:35:00",
            "tma": 51.2,
            "debit": 159.69
        },
        {
            "tanggal": "14 Jul 2022 05:40:00",
            "tma": 51.21,
            "debit": 161.209
        },
        {
            "tanggal": "14 Jul 2022 05:45:00",
            "tma": 51.21,
            "debit": 161.209
        },
        {
            "tanggal": "14 Jul 2022 05:50:00",
            "tma": 51.19,
            "debit": 158.177
        },
        {
            "tanggal": "14 Jul 2022 06:05:00",
            "tma": 51.19,
            "debit": 158.177
        },
        {
            "tanggal": "14 Jul 2022 06:10:00",
            "tma": 51.19,
            "debit": 158.177
        },
        {
            "tanggal": "14 Jul 2022 06:15:00",
            "tma": 51.21,
            "debit": 161.209
        },
        {
            "tanggal": "14 Jul 2022 06:35:00",
            "tma": 51.2,
            "debit": 159.69
        },
        {
            "tanggal": "14 Jul 2022 06:40:00",
            "tma": 51.2,
            "debit": 159.69
        },
        {
            "tanggal": "14 Jul 2022 06:50:00",
            "tma": 51.19,
            "debit": 158.177
        },
        {
            "tanggal": "14 Jul 2022 07:05:00",
            "tma": 51.17,
            "debit": 155.171
        },
        {
            "tanggal": "14 Jul 2022 07:10:00",
            "tma": 51.19,
            "debit": 158.177
        },
        {
            "tanggal": "14 Jul 2022 07:25:00",
            "tma": 51.19,
            "debit": 158.177
        },
        {
            "tanggal": "14 Jul 2022 07:30:00",
            "tma": 51.16,
            "debit": 153.678
        },
        {
            "tanggal": "14 Jul 2022 07:35:00",
            "tma": 51.17,
            "debit": 155.171
        },
        {
            "tanggal": "14 Jul 2022 07:45:00",
            "tma": 51.16,
            "debit": 153.678
        },
        {
            "tanggal": "14 Jul 2022 07:50:00",
            "tma": 51.16,
            "debit": 153.678
        },
        {
            "tanggal": "14 Jul 2022 07:55:00",
            "tma": 51.17,
            "debit": 155.171
        },
        {
            "tanggal": "14 Jul 2022 08:05:00",
            "tma": 51.16,
            "debit": 153.678
        },
        {
            "tanggal": "14 Jul 2022 08:15:00",
            "tma": 51.15,
            "debit": 152.191
        },
        {
            "tanggal": "14 Jul 2022 09:20:00",
            "tma": 51.13,
            "debit": 149.236
        }
    ],
    }
  },
  computed:{
    series(){
        let series= [{
            name: 'Elevasi (MDPL)',
            type: 'line',
            data: []
        }
        ]
        for (let index = 0; index < this.sampleData.length; index++) {
            const element = this.sampleData[index]
            series[0].data.push(element.tma)
        }
        for (let index = 0; index < 3; index++) {
            let sum=0
            for (let i = 0; i < series[0].data.length; i++) {
                sum += series[0].data[i]
            }
            let avg=(sum/(series[0].data.length))+(Math.random()*(0.80-0.10)+0.10)
            // let avg=57
            series[0].data.push(parseInt(avg))
        }
        return series
    },
    chartOptions(){
        let chartOptions= {
            chart: {
              height: 150,
              type: 'line',
              stacked: false,
            },
            stroke: {
              width: [1.5, 2, 5],
              curve: 'smooth',
              colors:'#392F5A'
            },
            plotOptions: {
              bar: {
                columnWidth: '50%'
              }
            },        
            fill: {
              opacity: [0.85, 0.25, 1],
              gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
              }
            },
            labels: [
            ],
            markers: {
              size: 2,
              colors:'#392F5A',
              strokeColors:'#392F5A'
            },
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              title: {
                text: 'MDPL',
              },
              min: 50,
              max: 67
            },
            annotations: {
                yaxis: [
                    {
                    y: 56.57,
                    y2: 57.07,
                    borderColor: '#0EE919',
                    fillColor: '#0EE919',
                    label: {
                        text: 'Siaga Hijau'
                    }
                    },
                    {
                    y: 57.07,
                    y2: 58.07,
                    borderColor: '#FFF829',
                    fillColor: '#FFF829',
                    label: {
                            text: 'Siaga Kuning'
                        }
                    },
                    {
                    y: 58.07,
                    y2: 66,
                    borderColor: '#FF3A3A',
                    fillColor: '#FF3A3A',
                    label: {
                            text: 'Siaga Merah'
                        }
                    }
                ],
                xaxis:[
                    {
                    x:0,
                    borderColor: '#775DD0',
                    label: {
                        style: {
                        color: 'black',
                        },
                        text: 'Batas Existing'
                    }
                    }
                ]
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return  y + " MDPL<br>"
                  }
                  return y
            
                }
              }
            }
          }
        chartOptions.annotations.xaxis[0].x=Date.parse(moment(this.sampleData[this.sampleData.length-1].tanggal,'DD MMM YYYY HH:mm:ss').toDate())
        let lastDate
        for (let index = 0; index < this.sampleData.length+3; index++) { 
            if(this.sampleData.length-1<=index){
                lastDate=Date.parse(moment(this.sampleData[this.sampleData.length-1].tanggal,'DD MMM YYYY HH:mm:ss').toDate())
                chartOptions.labels.push(lastDate+(60*24*60*1000*(index-(this.sampleData.length-1))))
            }else{
                const element = this.sampleData[index]
                console.log(element)
                let labelDate=Date.parse(moment(element.tanggal,'DD MMM YYYY HH:mm:ss').toDate())
                chartOptions.labels.push(labelDate)
            }
        }
        // for (let index = 0; index < 3; index++) {
        //     chartOptions.labels.push(Date.parse(moment(this.sampleData[this.sampleData.length].tanggal,'DD MMM YYYY HH:mm:ss').toDate()+index))
        // }
        return chartOptions
    }
  },
  mounted(){
    // for (let index = 0; index < this.sampleData.length; index++) {
    //     const element = this.sampleData[index]
    //         // "14 Jul 2022 08:05:00"
    //         this.chartOptions.labels.push(Date.parse(moment(element.tanggal,'DD MMM YYYY HH:mm:ss').toDate()))
    //         this.series[0].data.push(element.tma)
    //         // this.series[1].data.push(60)
    // }
  }
}
</script>