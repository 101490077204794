<template>
  <div class="">
      <side-bar @basemap='changeBasemap'></side-bar>
      <div id='map'></div>
  </div>
</template>
<script>
// @ is an alias to /src
import L from 'leaflet'
import * as turf from '@turf/turf'
import 'leaflet/dist/leaflet.css'
import sideBar from '../components/SideBar.vue'
import dataRumah from '../assets/data/rumah_4326.json'
import dataAWLR from '../assets/data/awlr_id_4326.geojson'
import dataTMASlider from '../assets/data/awlr_slider_4326.json'
import dataUlangSlider from '../assets/data/prob_slider_4326.json'
import dataGenanganTMA from '../assets/data/genanganTMA_10_4326.json'
import dataKecepatanTMA from '../assets/data/kecepatanTMA_10_4326.json'
import dataAdmID from '../assets/data/admin_id_4326.json'
import dataDASID from '../assets/data/Batas_DAS_KLHK_4326.json'
export default {
  name: 'Home',
  components:{sideBar},
  data(){
    return{
      map:'',basemaps:'',bm:'osm',layers:{admin:'',das:'',awlr:'',tma_slider:'',ulang_slider:'',genangan:'',kecepatan:'',kedalaman:'',rumah:''},
      dataRumah,dataAWLR,dataTMASlider,dataUlangSlider,dataGenanganTMA,dataKecepatanTMA,dataAdmID,dataDASID,
      sliderPolygon:[]
    }
  },
  methods:{
    baseLayer_Topo(){
       this.basemaps=L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '© OpenStreetMap'
        })
    },
    changeBasemap(val){
      this.map.removeLayer(this.basemaps)
      if(val=='osm'){
        this.basemaps=L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '© OpenStreetMap'
        })
      }else if(val=='topo'){
        this.basemaps=L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
          maxZoom: 20,
          attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        })
      }else{
        this.basemaps=L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        })
      }
      this.basemaps.addTo(this.map)
    },
    getAdmID(){
      this.layers.admin=L.geoJSON(this.dataAdmID)
      this.layers.admin.eachLayer((layer)=>{
        layer.setStyle({
                fillColor:'rgba(255, 248, 41,.4)',
                fillOpacity:1,
                color:'rgb(57, 47, 90)',
                weight:.3
        })
      })
    },
    getDASID(){
      this.layers.das=L.geoJSON(this.dataDASID)
      this.layers.das.eachLayer((layer)=>{
        layer.setStyle({
          fillColor:'rgba(83, 179, 252,.4)',
          fillOpacity:1,
          color:'rgb(57, 47, 90)',
          weight:.3
        })
      })
    },
    getAWLR(){
      // this.axios.get("/data/awlr_4326.geojson").then((result)=>{})
        this.layers.awlr=L.geoJSON(this.dataAWLR,{
          pointToLayer:(feature, latlng)=>{
            let imgUrl='/assets/triangle.png'
            if(feature.properties.tipepos=='1')imgUrl='/assets/triangle-yellow.png'
            else if(feature.properties.tipepos=='2')imgUrl='/assets/triangle-orange.png'
            else if(feature.properties.tipepos=='3')imgUrl='/assets/triangle-red.png'
            else imgUrl='/assets/triangle.png'
            return new L.Marker(latlng,{
                icon:new L.Icon({
                iconUrl: imgUrl,
                iconSize:     [7, 7], // size of the icon
                popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
              })
            })
          },
          onEachFeature:(feature,layer)=>{
            layer.on('click',()=>{
              let coor=feature.geometry.coordinates
              let props=feature.properties
              let payload={
                    clicked:true,awlr_name:feature.properties.name,das:feature.properties.induksunga,
                    sungai:feature.properties.sungai,koordinat:feature.geometry.coordinates,
                    tma:props.awlr,last_update:0,
              }
              this.$store.commit('awlr_clicked',payload)
              this.map.flyTo(new L.LatLng(coor[1],coor[0]),17)
            })
          }
        })
        this.layers.awlr.addTo(this.map)
    },
    getTMASlider(){
      this.layers.tma_slider=new L.GeoJSON(this.dataTMASlider)
      this.layers.tma_slider.eachLayer((layer)=>{
        if(layer.feature.properties.Count<=this.$store.state.simulasi_slider_value){
              layer.setStyle({
                fillOpacity:1,
                opacity:0
              })
            }else{
              layer.setStyle({
                fillOpacity:0,
                opacity:0
              })
            }
      })
    },
    getUlangSlider(){
      // this.axios.get('./data/prob_slider_4326.geojson').then((result)=>{})
        this.layers.ulang_slider=new L.GeoJSON(this.dataUlangSlider)
        this.layers.ulang_slider.eachLayer((layer)=>{
          if(layer.feature.properties.Count<=this.$store.state.simulasi_slider_value){
              layer.setStyle({
                fillOpacity:1,
                opacity:0
              })
            }else{
              layer.setStyle({
                fillOpacity:0,
                opacity:0
              })
            }
        })
    },
    getKedalamanTMA(){
      // this.axios.get('./data/genanganTMA_10_4326.geojson').then((result)=>{})
        this.layers.kedalaman=new L.GeoJSON(this.dataGenanganTMA)
        this.layers.kedalaman.eachLayer((layer)=>{
          if(layer.feature.properties.DN<=1)layer.setStyle({fillColor:'#f7fbff',fillOpacity:1,color:'#f7fbff'})
          else if(layer.feature.properties.DN>1 && layer.feature.properties.DN<=2)layer.setStyle({fillColor:'#d9e3ee',fillOpacity:1,color:'#d9e3ee'})
          else if(layer.feature.properties.DN>2 && layer.feature.properties.DN<=3)layer.setStyle({fillColor:'#bdcbdd',fillOpacity:1,color:'#bdcbdd'})
          else if(layer.feature.properties.DN>3 && layer.feature.properties.DN<=4)layer.setStyle({fillColor:'#a1b4cd',fillOpacity:1,color:'#a1b4cd'})
          else if(layer.feature.properties.DN>4 && layer.feature.properties.DN<=5)layer.setStyle({fillColor:'#879dbd',fillOpacity:1,color:'#879dbd'})
          else if(layer.feature.properties.DN>5 && layer.feature.properties.DN<=6)layer.setStyle({fillColor:'#6e86ac',fillOpacity:1,color:'#6e86ac'})
          else if(layer.feature.properties.DN>6 && layer.feature.properties.DN<=7)layer.setStyle({fillColor:'#566f9c',fillOpacity:1,color:'#566f9c'})
          else if(layer.feature.properties.DN>7 && layer.feature.properties.DN<=8)layer.setStyle({fillColor:'#3f598c',fillOpacity:1,color:'#3f598c'})
          else if(layer.feature.properties.DN>8 && layer.feature.properties.DN<=8)layer.setStyle({fillColor:'#27447c',fillOpacity:1,color:'#27447c'})
          else{
              layer.setStyle({
                fillColor:'#08306b',
                fillOpacity:1,
                color:'#08306b'
              })
          }
        })
    },
    getGenanganTMA(){
      // this.axios.get('./data/genanganTMA_10_4326.geojson').then((result)=>{})
        this.layers.genangan=new L.GeoJSON(this.dataGenanganTMA)
        this.layers.genangan.eachLayer((layer)=>{
              layer.setStyle({
                fillColor:'#73A4CD',
                fillOpacity:1,
                color:'#73A4CD'
              })
        })
    },
    getKecepatanTMA(){
      // this.axios.get('./data/kecepatanTMA_10_4326.geojson').then((result)=>{})
        this.layers.kecepatan=new L.GeoJSON(this.dataKecepatanTMA)
        this.layers.kecepatan.eachLayer((layer)=>{
          if(layer.feature.properties.DN<=0.59){
              layer.setStyle({fillColor:'#1E6997',fillOpacity:1,color:'#1E6997'})
          }else if(layer.feature.properties.DN>0.59 && layer.feature.properties.DN<=0.96){
              layer.setStyle({fillColor:'#23A5BC',fillOpacity:1,color:'#23A5BC'})
          }else if(layer.feature.properties.DN>0.96 && layer.feature.properties.DN<=1.16){
            layer.setStyle({fillColor:'#27E1E1',fillOpacity:1,color:'#27E1E1'})
          }else if(layer.feature.properties.DN>1.16 && layer.feature.properties.DN<=1.37){
            layer.setStyle({fillColor:'#2AD2B6',fillOpacity:1,color:'#2AD2B6'})
          }else if(layer.feature.properties.DN>1.37 && layer.feature.properties.DN<=1.58){
            layer.setStyle({fillColor:'#2CAB71',fillOpacity:1,color:'#2CAB71'})
          }else if(layer.feature.properties.DN>1.58 && layer.feature.properties.DN<=1.82){
            layer.setStyle({fillColor:'#5BAE53',fillOpacity:1,color:'#5BAE53'})
          }else if(layer.feature.properties.DN>1.82 && layer.feature.properties.DN<=2.07){
            layer.setStyle({fillColor:'#B6DB5B',fillOpacity:1,color:'#B6DB5B'})
          }else if(layer.feature.properties.DN>2.07 && layer.feature.properties.DN<=2.38){
            layer.setStyle({fillColor:'#F7E458',fillOpacity:1,color:'#F7E458'})
          }else if(layer.feature.properties.DN>2.38 && layer.feature.properties.DN<=2.88){
            layer.setStyle({fillColor:'#E77D3B',fillOpacity:1,color:'#E77D3B'})
          }else{
              layer.setStyle({
                fillColor:'#D71A1C',
                fillOpacity:1,
                color:'#D71A1C'
              })
          }
        })
    },
    getRumah(){
      let rumahCount=0
      this.layers.rumah=L.geoJSON(this.dataRumah,{
          pointToLayer:(feature, latlng)=>{
            let ifInsideFlood=this.checkRumahTerdampak(latlng,this.sliderPolygon)
            if(ifInsideFlood==true){
              rumahCount++
              return new L.Marker(latlng,{
                  icon:new L.Icon({
                  iconUrl: './assets/home-circle-outline.png',
                  iconSize:     [10, 10], // size of the icon
                  popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
                })
              })
            }else{
              // console.log('not found! '+ifInsideFlood)
              return new L.Marker(latlng,{
                  icon:new L.Icon({
                  iconUrl: './assets/home-circle-outline.png',
                  iconSize:     [0,0], // size of the icon
                  popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
                })
              })
            }
              
          },
          onEachFeature:(feature,layer)=>{
            layer.on('click',()=>{
              let coor=feature.geometry.coordinates[0]
              this.map.flyTo(new L.LatLng(coor[1],coor[0]),16)
              // console.log(this.$store.state.awlrPopup)
            })
          }
        })
      this.$store.commit('simulasi_slider_jumlah_bangunan_changed',rumahCount)
    },
    checkRumahTerdampak(marker,poly){
      let pointMarker=turf.point([marker.lng,marker.lat])
      let val=false
      for (let index = 0; index < poly.length; index++) {
        const element = poly[index]
        let feature=turf.polygon(element)
        if(turf.booleanPointInPolygon(pointMarker,feature)==true) val=true
      }
      return val
    },
  },
  watch:{
    '$store.state.das_checkbox':{
      handler(val){
        if(val)this.layers.das.addTo(this.map)
        else this.map.removeLayer(this.layers.das)
      }
    },
    '$store.state.adm_checkbox':{
      handler(val){
        if(val)this.layers.admin.addTo(this.map)
        else this.map.removeLayer(this.layers.admin)
      }
    },
    '$store.state.simulasi_radio':{
      handler(val){
        if(val=='tma'){
          this.layers.tma_slider.addTo(this.map)
          if(this.map.hasLayer(this.layers.ulang_slider)) this.map.removeLayer(this.layers.ulang_slider)
        }else{
          this.layers.ulang_slider.addTo(this.map)
          if(this.map.hasLayer(this.layers.tma_slider)) this.map.removeLayer(this.layers.tma_slider)
        }
      }
    },
    '$store.state.simulasi_slider_value':{
      handler(val){
        let area=[]
        let coordinates=[]
        if(this.map.hasLayer(this.layers.ulang_slider)){
          this.layers.ulang_slider.eachLayer((layer)=>{
            if(layer.feature.properties.Count<=val){
              coordinates.push(layer.feature.geometry.coordinates)
              let recentArea=layer.feature.properties.Area
              area.push(parseFloat(recentArea))
              layer.setStyle({
                fillOpacity:1,
                opacity:0
              })
            }else{
              layer.setStyle({
                fillOpacity:0,
                opacity:0
              })
            }
          })
        }else{
          this.layers.tma_slider.eachLayer((layer)=>{
            if(layer.feature.properties.Count<=val){
              coordinates.push(layer.feature.geometry.coordinates)
              let recentArea=layer.feature.properties.Area
              area.push(parseFloat(recentArea))
              layer.setStyle({
                fillOpacity:1,
                opacity:0
              })
            }else{
              layer.setStyle({
                fillOpacity:0,
                opacity:0
              })
            }
          })
        }
        let biggestArea=0
        for (let index = 0; index < area.length; index++) {
          const element = area[index]
          if(element>biggestArea){
            biggestArea=element
            this.sliderPolygon=coordinates[index]
          }
        }
        this.$store.commit('simulasi_slider_luas_changed',biggestArea)
      }
    },
    '$store.state.simulasi_btn.genangan':{
      handler(val){
        if(val){
          this.layers.genangan.addTo(this.map)
          this.map.fitBounds(this.layers.genangan.getBounds())   
        }
        else this.map.removeLayer(this.layers.genangan)
      }
    },
    '$store.state.simulasi_btn.kedalaman':{
      handler(val){
        if(val){
          this.layers.kedalaman.addTo(this.map)
          this.map.fitBounds(this.layers.kedalaman.getBounds())  
        }
        else this.map.removeLayer(this.layers.kedalaman)
      }
    },
    '$store.state.simulasi_btn.kecepatan':{
      handler(val){
        if(val){
          this.layers.kecepatan.addTo(this.map)
          this.map.fitBounds(this.layers.kecepatan.getBounds()) 
        }
        else this.map.removeLayer(this.layers.kecepatan)
      }
    },
    '$store.state.bangunan_checkbox':{
      handler(val){
        if(val){
          this.getRumah()
          this.layers.rumah.addTo(this.map)
        }else{
          this.map.removeLayer(this.layers.rumah)
        }
      }
    },
    '$store.state.recalculate_bangunan':{
      handler(){
          if(this.map.hasLayer(this.layers.rumah))this.map.removeLayer(this.layers.rumah)
          this.getRumah()
          this.layers.rumah.addTo(this.map)
      }
    }
  },
  mounted(){
    this.map=L.map('map', {
        center: [-2.61383897109847,116.82861328125001],
        zoom: 5,minZoom:5,zoomControl:false
    })
    L.Control.zoomHome = L.Control.extend({
      options: {
          position: 'topleft',
          zoomInText: '+',
          zoomInTitle: 'Zoom in',
          zoomOutText: '-',
          zoomOutTitle: 'Zoom out',
          zoomHomeText: '<img src="./assets/home.png"></img>',
          zoomHomeTitle: 'Zoom home'
      },

      onAdd: function (map) {
          var controlName = 'gin-control-zoom',
              container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
              options = this.options;

          this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle,
          controlName + '-in', container, this._zoomIn);
          this._zoomHomeButton = this._createButton(options.zoomHomeText, options.zoomHomeTitle,
          controlName + '-home', container, this._zoomHome);
          this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle,
          controlName + '-out', container, this._zoomOut);

          this._updateDisabled();
          map.on('zoomend zoomlevelschange', this._updateDisabled, this);

          return container;
      },

      onRemove: function (map) {
          map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function (e) {
          this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e) {
          this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: function () {
          this._map.flyTo([-2.61383897109847,116.82861328125001], 5);
      },

      _createButton: function (html, title, className, container, fn) {
          var link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;

          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this)
              .on(link, 'click', this._refocusOnMap, this);

          return link;
      },

      _updateDisabled: function () {
          var map = this._map,
              className = 'leaflet-disabled';

          L.DomUtil.removeClass(this._zoomInButton, className);
          L.DomUtil.removeClass(this._zoomOutButton, className);

          if (map._zoom === map.getMinZoom()) {
              L.DomUtil.addClass(this._zoomOutButton, className);
          }
          if (map._zoom === map.getMaxZoom()) {
              L.DomUtil.addClass(this._zoomInButton, className);
          }
      }
  })
    let customButtons=new L.Control.zoomHome()
    customButtons.addTo(this.map)
    this.baseLayer_Topo()
    this.basemaps.addTo(this.map)
    this.getAdmID()
    this.getDASID()
    this.getAWLR()
    this.getTMASlider()
    this.getUlangSlider()
    this.getGenanganTMA()
    this.getKedalamanTMA()
    this.getKecepatanTMA()
    this.getRumah()
  }
}
</script>
